import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import exceptdetail1 from '../images/except-detail-1.png';
import exceptdetail2 from '../images/except-detail-2.png';

export default () => (
<Layout>
<section className="pt-5 md:pt-5">
     <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-4/5">
          <h1 className="text-4xl lg:text-xl xl:text-2xl font-bold leading-none">
 			VUE Maps at Except
 		  </h1>
 		  <p className="text-lg lg:text-xl mt-6 font-light">by Eva Gladek</p>
		  <h2 className="text-4xl lg:text-xl xl:text-xl mt-5 font-bold leading-none">
 			Introduction
 		  </h2> 		  
		  <p className="text-lg lg:text-xl mt-6 font-light">At Except, we use VUE almost every day; because of its simplicity, we have found it to be the most useful tool available for quickly making system maps and causal loop diagrams.</p>		  
		  <img src={exceptdetail1}/>
		  <h2 className="text-4xl lg:text-xl xl:text-xl mt-5 font-bold leading-none">
 		  Using VUE
 		  </h2>
		 <p className="text-lg lg:text-xl mt-6 font-light">The two maps shown here are an example of how we use the program. They show simplified life cycle production chains. One is for the field production of tomatoes, the other shows the life cycle of beef and dairy production. The maps use different colors and shapes to represent upstream processes (grey squares), immediate products (yellow ovals), primary processes (lavender ovals), and final products (orange ovals). At each stage we have also indicated the major categories of impact entering and leaving the system (energy use, water use, greenhouse gas emissions, land use, waste generation, etc.). These impact categories are represented by the smaller colored circles.</p>		 
		  <img src={exceptdetail2}/>
		  <h2 className="text-4xl lg:text-xl xl:text-xl mt-5 font-bold leading-none">
 		  Creating Extensive Charts
 		  </h2>
		  <p className="text-lg lg:text-xl mt-6 font-light">To make this graphic, we used VUE's standard features: nodes, links, and layers. To create a frame around the main part of the process diagram, we also used very small nodes as connectors (the small purple dots in the corners of the red frame) to create a free-form shape. This also created a frame that is not ³active,² meaning that objects can be moved into and out of it without getting embedded within a node.</p>
          <h2 className="text-4xl lg:text-xl xl:text-xl mt-5 font-bold leading-none">
 		  Additional Applications of VUE
 		  </h2>
          <p className="text-lg lg:text-xl mt-6 font-light">We sometimes export VUE maps as PDFs and modify their colors and aesthetics in a vector editing software. That was not done in the case of these examples, but it can be useful when trying to make uniform graphics for a report.</p>
		  <p className="text-lg lg:text-xl mt-6 font-light">All of our work is Creative Commons BY-SA-NC licensed, so as long as you credit Except, you are free to reuse it. Visit our website for a closer look at what we do: www.except.nl.</p>          
		  <p className="text-sm lg:text-sm mt-6 font-light">Except is a team of researchers, consultants and designers who focus on topics relating to sustainability and the world around us. They do work in a variety of areas, including business strategy, policy, architecture and research.</p>
 		</div>
    </div>
</section>
</Layout>
)